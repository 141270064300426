@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600;700&display=swap");

.main-gradient {
  background-image: linear-gradient(
    to bottom,
    #e6fff5,
    #f4ffff,
    #ffffff,
    #ffffff
  );
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-x: hidden;
}

.header {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  box-sizing: border-box;
}

.body {
  background-color: white;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%;
  height: auto;
  margin-right: 10px;
  width: 20vw;
}

@media (min-width: 1200px) {
  .logo img {
    width: 15vw;
  }
}

@media (max-width: 768px) {
  .logo img {
    width: 25vw;
  }
}

@media (max-width: 480px) {
  .logo img {
    width: 35vw;
  }
}

.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #2a4a8f;
  font-weight: 600;
  background-color: transparent;
  border: 2px solid #2a4a8f;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: auto;
}

.share-button:hover {
  background-color: #2a4a8f;
  color: #fff;
}

.share-button svg {
  margin-right: 8px;
}

.share-button svg:hover {
  color: #fff;
}

@media (min-width: 1200px) {
  .share-button {
    padding: 12px 24px;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .share-button {
    padding: 8px 16px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .share-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

.MainHeading {
  font-size: 48px;
  font-family: "Exo", sans-serif;
  font-weight: 700;
  color: #344054;
  text-align: center;
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .MainHeading {
    font-size: 48px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .MainHeading {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .MainHeading {
    font-size: 26px;
  }
}
.subHeading {
  font-size: 30px;
  font-family: "Exo", sans-serif;
  font-weight: 500;
  color: #667085;
  text-align: center;
  margin-top: 24px;
}
@media (min-width: 1200px) {
  .subHeading {
    font-size: 38px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .subHeading {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .subHeading {
    font-size: 20px;
  }
}
.carbon-section {
  color: #12b76a;
  font-family: "Exo", sans-serif;
  font-weight: 600;
  font-size: 90px;
  text-align: center;
}
@keyframes countUp {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-in-out {
  animation: zoomInOut 0.6s ease-in-out forwards;
}

.counter-animation {
  animation: countUp 0.5s ease-in-out;
  display: inline-block;
}

.zoom-in {
  animation: zoomIn 0.3s ease-in-out forwards;
}
@media (min-width: 1200px) {
  .carbon-section {
    font-size: 108px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .carbon-section {
    font-size: 68px;
  }
}

@media (max-width: 767px) {
  .carbon-section {
    font-size: 58px;
  }
}
.carbon-text {
  color: #027a48;
  font-family: "Exo", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
@media (min-width: 1200px) {
  .carbon-text {
    font-size: 28px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .carbon-text {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .carbon-text {
    font-size: 18px;
  }
}

.counter-animation {
  animation: countUp 30s ease-in-out;
  display: inline-block;
}

.carbonImage {
  margin: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  margin-bottom: 60px;
}

.carbonImage-svg {
  width: 100%;
  max-width: 600px;
  height: auto;
  align-self: center;
  justify-content: center;
}

.support-text {
  text-align: center;
}

.SubTextSection {
  font-size: 18px;
  color: #475467;
  font-family: "Exo", sans-serif;
  font-weight: 600;
  display: block;
}
@media (min-width: 1200px) {
  .SubTextSection {
    font-size: 28px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .SubTextSection {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .SubTextSection {
    font-size: 18px;
  }
}

.gogreenSection {
  color: #027a48;
  font-weight: 600;
  font-family: "Exo", sans-serif;
  font-size: 24px;
  text-align: center;
}
@media (min-width: 1200px) {
  .gogreenSection {
    font-size: 28px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .gogreenSection {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .gogreenSection {
    font-size: 18px;
  }
}
.conceptText {
  color: #344054;
  font-family: "Exo", sans-serif;
  display: inline-flex;
  font-weight: 600;
  margin-left: 5px;
  align-items: center;
  margin-bottom: 3px;
}
@media (min-width: 1200px) {
  .conceptText {
    font-size: 28px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .conceptText {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .conceptText {
    font-size: 18px;
  }
}
.LeafIcon {
  margin-left: 0px;
}
.textSection {
  font-weight: 400;
  font-family: "Exo", sans-serif;
  color: #667085;
  margin: 0 auto;
  text-align: center;
  width: 90%;
}
@media (min-width: 1200px) {
  .textSection {
    font-size: 24px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .textSection {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .textSection {
    font-size: 14px;
  }
}
.slingMobility {
  font-size: 18px;
  font-weight: 600;
  font-family: "Exo", sans-serif;
  color: #3b5d9e;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .slingMobility {
    font-size: 24px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .slingMobility {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .slingMobility {
    font-size: 15px;
  }
}
.arrow-icon {
  margin-left: 8px;
}
.divider {
  border: 0;
  border-top: 4px solid #e4e7ec;
  width: 90%;
  margin: 20px auto;
  font-family: "Exo", sans-serif;
  display: block;
}
.socialText {
  color: #1d2939;
  margin-top: 35px;
  font-weight: 600;
  font-family: "Exo", sans-serif;
  text-align: center;
}
@media (min-width: 1200px) {
  .socialText {
    font-size: 24px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .socialText {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .socialText {
    font-size: 18px;
  }
}
.socialsub {
  color: #1d2939;
  font-weight: 400;
  font-family: "Exo", sans-serif;
  font-size: 25px;
  text-align: center;
}
@media (min-width: 1200px) {
  .socialsub {
    font-size: 24px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .socialsub {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .socialsub {
    font-size: 14px;
  }
}
.socials {
  justify-content: center;
  display: flex;
  width: auto;
  height: auto;
  background-color: #ffffff;
}

.social-icon {
  width: 30px;
  height: 30px;
  fill: #1c3d7e;
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 40px;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

@keyframes fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0.8;
  }
}

.snow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 999;
}

.snowflake {
  position: absolute;
  top: -10px;
  background-color: transparent;
  color: #a0cfff;
  font-size: 10px;
  animation: fall 3s linear infinite;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .snowflake {
    font-size: 4px !important;
    animation-duration: 4s;
  }
}

@media (max-width: 480px) {
  .snowflake {
    font-size: small !important;
    animation-duration: 3s;
  }
}

.snowflake:after {
  content: "❄";
  display: block;
  font-size: inherit;
}

.snowflake:nth-child(odd) {
  opacity: 0.7;
}

.snowflake:nth-child(1) {
  left: 70%;
  font-size: 30px;
  animation-delay: 0s;
  animation-duration: 4.5s;
}

.snowflake:nth-child(2) {
  left: 75%;
  font-size: 25px;
  animation-delay: 0.5s;
  animation-duration: 5s;
}

.snowflake:nth-child(3) {
  left: 80%;
  font-size: 20px;
  animation-delay: 1s;
  animation-duration: 4.8s;
}

.snowflake:nth-child(4) {
  left: 85%;
  font-size: 24px;
  animation-delay: 0.2s;
  animation-duration: 5.2s;
}

.snowflake:nth-child(5) {
  left: 90%;
  font-size: 22px;
  animation-delay: 0.6s;
  animation-duration: 4.4s;
}

.snowflake:nth-child(6) {
  left: 5%;
  font-size: 20px;
  animation-delay: 0.8s;
  animation-duration: 4.6s;
}

.snowflake:nth-child(7) {
  left: 15%;
  font-size: 18px;
  animation-delay: 1.5s;
  animation-duration: 4.2s;
}

.snowflake:nth-child(8) {
  left: 30%;
  font-size: 22px;
  animation-delay: 1s;
  animation-duration: 5.5s;
}

.snowflake:nth-child(9) {
  left: 50%;
  font-size: 20px;
  animation-delay: 0.3s;
  animation-duration: 4.9s;
}

.snowflake:nth-child(10) {
  left: 60%;
  font-size: 24px;
  animation-delay: 0.9s;
  animation-duration: 5.1s;
}

.snowflake:nth-child(11) {
  left: 72%;
  font-size: 25px;
  animation-delay: 0.2s;
  animation-duration: 5.6s;
}

.snowflake:nth-child(12) {
  left: 77%;
  font-size: 28px;
  animation-delay: 0.7s;
  animation-duration: 5.2s;
}

.snowflake:nth-child(13) {
  left: 83%;
  font-size: 26px;
  animation-delay: 0.4s;
  animation-duration: 5.7s;
}

.snowflake:nth-child(14) {
  left: 88%;
  font-size: 24px;
  animation-delay: 0.6s;
  animation-duration: 5.3s;
}

.snowflake:nth-child(15) {
  left: 93%;
  font-size: 30px;
  animation-delay: 0.9s;
  animation-duration: 4.9s;
}

.snowflake:nth-child(16) {
  left: 10%;
  font-size: 15px;
  animation-delay: 1.2s;
  animation-duration: 5.4s;
}

.snowflake:nth-child(17) {
  left: 25%;
  font-size: 17px;
  animation-delay: 0.8s;
  animation-duration: 5.5s;
}

.snowflake:nth-child(18) {
  left: 35%;
  font-size: 19px;
  animation-delay: 1s;
  animation-duration: 5.8s;
}

.snowflake:nth-child(19) {
  left: 45%;
  font-size: 21px;
  animation-delay: 1.4s;
  animation-duration: 5.2s;
}

.snowflake:nth-child(20) {
  left: 55%;
  font-size: 18px;
  animation-delay: 1.3s;
  animation-duration: 4.6s;
}

.snowflake:nth-child(21) {
  left: 48%;
  font-size: 27px;
  animation-delay: 0.3s;
  animation-duration: 6.4s;
}

.snowflake:nth-child(22) {
  left: 22%;
  font-size: 29px;
  animation-delay: 0.2s;
  animation-duration: 4.8s;
}

.snowflake:nth-child(23) {
  left: 25%;
  font-size: 26px;
  animation-delay: 0.7s;
  animation-duration: 6.5s;
}

.snowflake:nth-child(24) {
  left: 10%;
  font-size: 23px;
  animation-delay: 0.5s;
  animation-duration: 5s;
}

.snowflake:nth-child(25) {
  left: 15%;
  font-size: 28px;
  animation-delay: 1s;
  animation-duration: 4.8s;
}

.decorative {
  position: absolute;
  z-index: 2;
}

.star {
  width: 30px;
  height: 30px;
  padding-top: 10px;
  position: absolute;
}

.star.top-left {
  top: 110px;
  left: 30px;
}

.star.bottom-right {
  bottom: -30px;
  right: 20px;
}

.candy-cane {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 140px;
  right: 0px;
  z-index: 2;
}

.crystal {
  width: 50px;
  height: 50px;
}

.crystal.left {
  position: absolute;
  top: 300px;
  left: 0px;
}
