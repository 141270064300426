/* termsAndConditions.css */

.background-color {
  background-color: aliceblue;
  min-height: 100vh;
}

.header-logo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.logo-image {
  width: 200px;
}

.terms-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.terms-heading {
  font-size: 2em;
  margin-bottom: 30px;
  text-align: center;
  color: black;
  font-weight: 600;
}

.effective-date {
  font-size: 1em;
  color: #0a0909;
  margin-bottom: 20px;
  text-align: justify;
}

.terms-section {
  margin-bottom: 30px;
  text-align: justify;
}

.terms-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #1c3d7e;
}

.terms-section p {
  font-size: 1em;
  line-height: 1.6;
  color: #0a0909;
}

.terms-section ol {
  color: #000;
  font-size: 1em;
}

@media (max-width: 600px) {
  .terms-container {
    padding: 10px;
  }

  .terms-heading {
    font-size: 1.5em;
  }

  .terms-section h2 {
    font-size: 1.25em;
  }
}
