.ecosystem-btn button{
  background-color: var(--slingBlue) !important;
  padding: 10px;
  height: 60px;
  width: 60px;
}

.MuiSpeedDialAction-staticTooltipLabel {
  width: max-content;
  background: linear-gradient(270deg, rgba(17, 34, 75, 0.80) -68.92%, rgba(24, 50, 108, 0.00) 50%);
  backdrop-filter: blur(10px) !important;
}