.navbar-custom {
  background: #1C1C1C;
  z-index: 9999;
}

.navbar-custom .navbar-brand {
  max-width: 120px;
}

.navbar-custom .navbar-nav>li {
  padding: 10px 15px !important;
}

.navbar-custom .navbar-nav>li>a {
  font-weight: 500;
  color: var(--white);
  padding: 0;
}

.navbar-custom .navbar-nav>li>.active {
  border: 1px solid var(--white);
  border-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.navbar-custom .navbar-nav>li>.dropdown-menu>.active {
  background-color: var(--slingBlue);
}

.navbar-custom .navbar-nav>li>.dropdown-menu>.dropdown-item:hover {
  background-color: var(--slingBlue);
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .navbar-custom .navbar-nav>li>.active {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}