.hero {
  background-image: url('./Hero.jpg');
  background-size: cover;
  height: 100vh;
  color: var(--white);
  background-position: center;
  display: flex;
  align-items: flex-end;
}

.home-bike-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .home-bike-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.hero-bike-card {
  overflow: hidden;
}

.hero-bike-card .image-container {
  transform: translate(-48%, 0px);
  transition: transform 0.5s ease-in-out;
}

.hero-bike-card:hover .image-container {
  transform: translate(0, 0);
}