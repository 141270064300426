

  .members-grid{
    display: grid;
    grid-gap: 2rem;
  }
  
  @media only screen and (max-width: 600px) {
    .members-grid{
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media only screen and (min-width: 600px) {
    .members-grid{
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media only screen and (min-width: 768px) {
    .members-grid{
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media only screen and (min-width: 992px) {
    .members-grid{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .member-img {
    border-radius: 12px;
    width: 150px;

  }