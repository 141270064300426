.carousel-img {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  object-fit: contain;
}

.bike-model {
  font-size: 50px;
  font-weight: 600;
  line-height: normal;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

.section {
  font-size: 30px;
  font-weight: 600;
}