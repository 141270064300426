@import url('./Colours.css');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.App {
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  text-align: left;
  background: #1C1C1C;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

Link {
  text-decoration: none;
}

Link:hover {
  text-decoration: none;
}

.text-red {
  color: var(--slingRed);
}

.text-orange {
  color: var(--orange);
}

.text-blue {
  color: var(--slingBlue);
}

.text-green {
  color: var(--green);
}

.text-pastel-green {
  color: var(--pastelGreen);
}

.text-light-blue {
  color: var(--lightBlue);
}

.page-title {
  background: linear-gradient(268deg, #23479A -12.13%, #FFF 98.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card {
  padding: 20px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.347);
  border-radius: 30px;
  border: 1px solid rgba(140, 140, 140, 0.20);
  background: linear-gradient(270deg, rgba(17, 34, 75, 0.80) -68.92%, rgba(24, 50, 108, 0.00) 50%);
  backdrop-filter: blur(10px) !important;
  text-align: left;
  color: var(--white) !important;
}