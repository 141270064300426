.primary-btn {
  background-color: var(--slingBlue) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 10px 36px 0 rgba(0, 0, 0, 0.347);
  padding: 10px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  color: var(--white) !important;
}

.primary-hollow-btn {
  color: var(--white) !important;
  border-radius: 0.5rem !important;
  border: 2px solid var(--white) !important;
  padding: 8px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.red-btn {
  background-color: var(--slingRed) !important;
}

.btn-tabs {
  display: flex;
  justify-content: center;
  height: 50px;
}

.btn-tabs :nth-child(1) {
  border-radius: 10px 0px 0px 10px;
}

.btn-tabs :nth-child(2) {
  border-radius: 0px;
}

.btn-tabs :nth-child(3) {
  border-radius: 0px 10px 10px 0px;
}

.btn-tabs>.active {
  background-color: var(--slingBlue) !important;
  color: var(--white) !important;
}

.btn-tabs>.inactive {
  background-color: var(--white) !important;
  color: var(--slingBlue) !important;
}