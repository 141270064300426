footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: linear-gradient(180deg, rgba(17, 34, 75, 0.80) -68.92%, rgba(24, 50, 108, 0.00) 50%) !important;
  backdrop-filter: blur(10px) !important;
  color: var(--white) !important;
}

footer ul li {
  line-height: 40px;
  list-style: none;
}