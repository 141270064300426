.subscription-card {
  padding: 30px;
  background-image: url('./Battery.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin-right: 25px;
  margin-left: 25px;
  text-align: center;
  transition: transform .5s ease;
  color: var(--white);
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.subscription-card:hover {
  transform: scale(1.05);
}