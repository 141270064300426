.invoice {
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .invoice h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .invoice-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .invoice-item .label {
    font-weight: bold;
  }