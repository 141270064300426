.articlecard {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; 
}

.article-thumbnail {
  width: 100%; 
  height: 200px; 
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}
