.card-wrapper {
  position: relative;
}

.card-select {
  max-width: 190px;
  text-align: center;
  padding: 0px !important;
  border-radius: 15px !important;
  background: linear-gradient(0deg, rgba(17, 34, 75, 0.80) -68.92%, rgba(24, 50, 108, 0.00) 50%) !important;
  position: relative;
}

.card-select svg {
  color: var(--green) !important;
}

/* Status container for checkmark and out-of-stock label */
.card-status {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.status-icon {
  font-size: 20px;
}

/* "Out of Stock" label styling */
.out-of-stock-label {
  background: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 10px;
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}

/* Selected card styling */
.card-select.active {
  border: 4px solid var(--slingBlue) !important;
}

/* Disabled (Out of Stock) card styling */
.card-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Bike image styling */
.card-select .bike-img {
  object-fit: cover;
  width: 100px;
  max-height: 125px;
}

/* Partner logo image styling */
.card-select .partner-img {
  height: 110px;
}
