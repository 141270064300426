.stepper .MuiStepLabel-label{
  font-size:x-large;
}

.select-card-grid {
  display: grid;
  grid-gap: 2rem;
}

@media only screen and (max-width: 600px) {
  .select-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  .select-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .select-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .select-card-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
